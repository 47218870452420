.hover50 {
  opacity: 0.5 !important;
  &:hover {
    opacity: 1 !important;
  }
}

/* Quill editor global overrides for ra-input-rich-text. */
.ql-editor {
  min-height: 200px;
}

.warningLabel {
  color: maroon !important;
}

.small-image {
  max-width: 310px;
}

.bold {
  font-weight: bold;
}

.sidebarBreakpoints {
  @media (max-width: 724px) {
    position: absolute;
  }
  @media (min-width: 725px) {
    position: fixed;
  }
}
// react-sortable inserts the active row at the end of the body, so we need to re-apply styles
body > .sortableRow {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

details {
  summary {
    cursor: pointer;
    div {
      display: flex;
      align-items: center;
    }
  }
  .openclose {
    &:hover {
      background: #eee;
      border-radius: 100%;
    }
    margin: 8px;
    padding: 8px;
    transition: transform 0.5s;
  }
  .hidden {
    margin-bottom: 24px;
  }
}

details[open] .openclose {
  transform: rotate(180deg);
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
.page-description {
  margin-bottom: 16px;
  font-size: 14px;
  color: #555;
}