//sortable classes
.dragHandle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.sortableList {
  position: relative;
  z-index: 1;
}

.sortableRow {
  padding: 10px 0;
  display: flex;
  align-items: center;
  z-index: 2;
  gap: 24px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }
}

.sortableRowBody {
  flex-grow: 1;
}
