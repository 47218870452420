.DatepickerWrapper input {
    background: whitesmoke;
    border: none;
    border-bottom: 1px solid #8e8e8e;
    height: 50px;
    width: 205px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0 10px;
    margin-bottom: 25px;
}
.DatepickerPopper {
    z-index: 9;
}
.wrapper-container{
    position: relative;
}
.no-border {
    border: none;
    padding: 0;
}
.no-border input {
    margin-bottom: 0;
    width: 250px;
}
.no-margin {
    margin: 0;
}
.j-string {
    font-size: 8px;
}
.text-blue-line {
    background: #006edc;
    color: #006edc;
    border: 1px solid #006edc;
    margin: 0;
}
.recording-section.recorder {
    margin-bottom: 10px;
}
.recording-section {
    background: #f1f3f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 10px;
    border-radius: 30px;
    font-size: 14px;
}
.recording-section-inner {
    position: relative;
    margin: 0px 15px;
    width: 100%;
    height: 4px;
    background-color: rgb(193 194 195);
    overflow: hidden;
    border-radius: 10px;
}
.recorder-item {
    display: flex;
    align-items: center;
}
.recorder-item .recording-section {
    width: 100%;
}
.blinking-rec {
    animation: blink 1s infinite; 
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}