.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
}

.status-active {
  background-color: #4caf50;
}

.status-inactive {
  background-color: #f44336;
}

.highlight .event-title {
  animation: highlightFade 2s ease;
  padding: 4px 8px;
  border-radius: 4px;
}

@keyframes highlightFade {
  0% {
    background-color: rgba(255, 255, 0, 0.5);
  }
  100% {
    background-color: transparent;
  }
}

.sortable-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar {
  background-color: white;
  margin-top: 0;
  justify-content: center;
  flex: 1;
  display: flex;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}

.head-title-content {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.search-input {
  min-width: 300px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
